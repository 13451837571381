function submit_recaptcha_form(token) {
  document.getElementById("contact_form").submit();
}
jQuery(window).on("load", function () {
  jQuery("body").removeClass("preload");
});

jQuery(function ($) {
  var winW = "",
    winH = "";

  var slideWrapper = $(".home-header .header-slider"),
    iframes = slideWrapper.find(".embed-player");

  var newsSlideWrapper = $(".home-news .slider");

  $(document).ready(init);

  $(".toggleSearch").click(function () {
    $(".searchFormContainer").addClass("active");
  });

  $(".closeSearch").click(function () {
    $(".searchFormContainer").removeClass("active");
  });

  function init() {
    setTimeout(function () {
      fixAside();
    }, 500);
    resize();
    $(window).on("resize", function () {
      fixAside();
      resize();
    });

    headerSubmenu();
    mobileMenu();

    smoothScroll();
    bgImg();
    select();
    $.datepicker.regional["fr"] = {
      closeText: "Fermer",
      prevText: "&#x3c;Préc",
      nextText: "Suiv&#x3e;",
      currentText: "Aujourd'hui",
      monthNames: [
        "Janvier",
        "Fevrier",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Avr",
        "Mai",
        "Jun",
        "Jul",
        "Aou",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
      weekHeader: "Sm",
      dateFormat: "dd-mm-yy",
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: "",
      minDate: 0,
      maxDate: "+12M +0D",
      numberOfMonths: 2,
      showButtonPanel: true,
    };
    $.datepicker.setDefaults($.datepicker.regional["fr"]);
    if ($(".datepicker").length) {
      $(".datepicker").datepicker({
        // dateFormat: "dd MM"
        dateFormat: "dd",
      });
    }

    stickyNav();
    slick();

    modalVideo();
    //modalImage();
    modalGallery();

    formSyndications();

    homepageSearch();

    $(".block-cols--offset")
      .closest(".destination-item")
      .find(".item-header")
      .addClass("item-header--bigger");

    homeIntroSlider();

    newsSlider();

    AOS.init({
      // offset: 200,
      duration: 600,
      easing: "ease-in-out-sine",
      // delay: 100,
    });

    $(".hide-info").on("click", function (e) {
      e.preventDefault();
      $(".hide-mob").slideToggle();
      $(this).toggleClass("active");
      if ($(this).hasClass("active")) {
        $(".hide-info").text("Voir les infos de contact & accès");
      } else {
        $(".hide-info").text("Masquer les infos de contact & accès");
      }
    });

    $.extend($.validator.messages, {
      required: "Ce champ est obligatoire.",
      email: "Adresse email non valide.",
      date: "Veuillez fournir une date valide.",
      number: "Veuillez fournir un numéro valide.",
      digits: "Veuillez fournir seulement des chiffres.",
    });

    // Ajax MAP Filter
    map_filter_ajax();

    newsletter_form();

    contact_form();
  }

  function contact_form() {
    $("#contact_form").validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        error.addClass("form-row-error error");
        var parentElm = element.closest(".form-group");
        parentElm.append(error);
      },
      submitHandler: function (form) {
        grecaptcha.execute();
        //form.submit();
      },
    });
  }

  function newsletter_form() {
    $("#newsletter_form").validate({
      errorElement: "div",
      errorPlacement: function (error, element) {
        error.addClass("form-row-error error");
        var parentElm = element.parent("#newsletter_form");
        parentElm.append(error);
      },
      submitHandler: function (form) {
        var cForm = $("#newsletter_form");
        loader(true);
        $.ajax({
          type: "POST",
          url: "/",
          data: cForm.serialize(), // serializes the form's elements.
          success: function (response) {
            loader(false);
            if (response.success == true) {
              $("#newsletter_form").after(
                '<div class="success">' + response.message + "</div>"
              );
              $("#newsletter_form").remove();
            } else {
              if (response.error) {
                alert(response.error);
              }
            }
          },
          error: function (response) {
            loader(false);
            alert("Erreur JS CODE 1");
          },
        });
      },
    });

    // $('#newsletter_form').on('submit', function(e) {
    //     e.preventDefault();
    //     if ($('#nl_email').val() == '') {
    //         $('#nl_email').val()
    //         return false;
    //     }
    //     loader(true);

    // });
  }

  function map_filter_ajax() {
    var cForm = $("#map_search_datas");

    $("#map_search_datas select.depends.hidden")
      .next(".select2-container")
      .hide();

    // Base Change
    $("#map_type_search").on("change", function () {
      var typeSelect = $(this).val();
      // Reset all and display
      //@todo RESET
      $("#map_search_datas select.trigger-form").val("");
      $("#map_search_datas select.trigger-form").trigger("change"); // Notify any JS components that the value changed

      // $('#map_search_datas select.trigger-form').select2("val", "");

      $("#map_search_datas select.depends").val("");
      $("#map_search_datas select.depends").next(".select2-container").hide();
      if ($("#" + typeSelect).length) {
        $("#" + typeSelect)
          .next(".select2-container")
          .show();
      }
    });

    cForm.find("select.trigger-form").on("change", function () {
      $.ajax({
        type: "POST",
        url: ".",
        data: cForm.serialize(), // serializes the form's elements.
        success: function (response) {},
        error: function (response) {},
      });
    });
  }

  function bgImg() {
    $("[data-bg]").each(function () {
      var dataImg = $(this).data("bg");
      $(this).css("background-image", "url(" + dataImg + ")");
    });
  }

  function headerSubmenu() {
    var allLi0 = $(".site-header .main-menu >ul >li");

    $(".menu-goout").each(function () {
      var maxH = 0;

      var parentElm = $(this);

      parentElm.find(".submenu li.level-1 .submenu").each(function () {
        var cElmH = $(this).height();
        if (cElmH > maxH) {
          maxH = cElmH;
        }
      });

      if (maxH) {
        parentElm.find(".level-0>.submenu").css("min-height", maxH + 80);
      }

      if (!parentElm.find(".active-subitem").length) {
        parentElm.find(".submenu li:first-child").addClass("active-subitem");
      }

      var baseElm = parentElm.find(".active-subitem");

      $(this)
        .find(".submenu li.level-1")
        .hover(
          function () {
            baseElm.removeClass("active-subitem");
            $(this).addClass("active-subitem");
          },
          function () {
            $(this).removeClass("active-subitem");
            baseElm.addClass("active-subitem");
          }
        );
    });

    allLi0.find(">a").on("click", function (e) {
      e.preventDefault();
      allLi0.not($(this).closest("li")).removeClass("active");
      $(this).closest("li").toggleClass("active");
    });

    if (winW > 991) {
      allLi0.append('<div class="submenu-close"></div>');

      $(document).on("click", ".submenu-close, .site-content", function () {
        allLi0.removeClass("active");
      });

      $(".menu-goout >li.active >.submenu >li:not(.active)")
        .mouseenter(function () {
          $(".menu-goout >li >.submenu >li").removeClass("active");
          $(this).addClass("active");
        })
        .mouseleave(function () {
          $(this).removeClass("active");
        });
    }
  }

  function mobileMenu() {
    // menu toggle
    $(".header-toggle").on("click", function (e) {
      e.preventDefault();
      var parent = $(this).closest(".site-header");

      $("html").toggleClass("no-scroll");
      $("body").toggleClass("menu-open");
      parent.toggleClass("open");
      $(".menu-responsive").toggleClass("active");
    });

    // menu mobile sub menu toggle
    if (winW <= 991) {
      $(".main-menu >ul >li >.submenu >.menu-item-has-children >a").on(
        "click",
        function (e) {
          e.preventDefault();

          var parent = $(this).parent();
          var submenu = parent.find(".submenu");
          parent.toggleClass("active");

          $(".main-menu >ul >li >.submenu >.menu-item-has-children")
            .not(parent)
            .removeClass("active");

          parent.siblings("li").find(".submenu").slideUp();

          submenu.slideToggle();
          /*
                                if (parent.hasClass('active')) {
                                  /!*  $('.sub-menu').not(submenu).slideUp();
                                    submenu.slideDown();*!/

                                } else {
                                    submenu.slideUp();
                                }*/
        }
      );
    }
  }

  function modalVideo() {
    // search modal
    $(".video-toggle").on("click", function (e) {
      e.preventDefault();
      var video = $(this).data("video");
      $("#modal-video iframe").attr(
        "src",
        video + "?rel=0&modestbranding=1&byline=0&portrait=0&autoplay=1"
      );
      var videoTitle = $(this).data("video-title");
      var videoDesc = $(this).data("video-desc");

      $("#modal-video .video-title").html(videoTitle);
      $("#modal-video .video-desc").html(videoDesc);

      // Show modal
      $("#modal-video").modal();

      $("#modal-video").on("hidden.bs.modal", function () {
        $("#modal-video iframe").attr("src", "");
        $("#modal-video .video-title").html("");
        $("#modal-video .video-desc").html("");
      });
    });
  }

  function modalImage() {
    // search modal
    $(".thumb-toggle").on("click", function (e) {
      e.preventDefault();
      var image = $(this).data("src");
      var caption = $(this).data("caption");
      $("#modal-img img").attr("src", image);
      $("#modal-img .caption").html(caption);
      // Show modal
      $("#modal-img").modal();

      $("#modal-img").on("hidden.bs.modal", function () {
        $("#modal-img img").attr("src", "");
        $("#modal-img .caption").html("");
      });
    });
  }

  function modalGallery() {
    // $('.slider-for').slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   fade: true,
    //   arrows: false,
    //   asNavFor: '.gallery-slider-single'
    // });

    $(".gallery-slider-single").slick({
      infinite: true,
      dots: true,
      arrows: false,
      // asNavFor: '.slider-for',
      adaptiveHeight: false,
      accessibility: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    // // search modal
    // $('.gallery-toggle').on('click', function (e) {
    //         e.preventDefault();
    //         var slider = $(this).find('.thumb-slider').html();

    //         $("#modal-gallery .modal-slider").fadeOut().append(slider).fadeIn();

    //         setTimeout(function () {
    //             $("#modal-gallery .modal-slider").slick({
    //                 infinite: true,
    //                 dots: false,
    //                 arrows: true,
    //                 adaptiveHeight: false,
    //                 accessibility: true,
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 prevArrow: '<div class="arrow-slider arrow-slider--left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" class="ico ico-arrow"><path d="M22.81,3.93,12.57,14.28a.78.78,0,0,1-1.1,0L1.22,3.93a.78.78,0,0,1,0-1.1l1.1-1.1a.78.78,0,0,1,1.1,0l8.05,8.14a.78.78,0,0,0,1.1,0l8-8.1a.78.78,0,0,1,1.1,0l1.1,1.1a.83.83,0,0,1,0,1Z"/></svg></div>',
    //                 nextArrow: '<div class="arrow-slider arrow-slider--right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16" class="ico ico-arrow"><path d="M22.81,3.93,12.57,14.28a.78.78,0,0,1-1.1,0L1.22,3.93a.78.78,0,0,1,0-1.1l1.1-1.1a.78.78,0,0,1,1.1,0l8.05,8.14a.78.78,0,0,0,1.1,0l8-8.1a.78.78,0,0,1,1.1,0l1.1,1.1a.83.83,0,0,1,0,1Z"/></svg></div>',
    //             });
    //         }, 140);

    //         // Show modal
    //         $('#modal-gallery').modal();

    //         $('#modal-gallery').on('hidden.bs.modal', function () {
    //             $("#modal-gallery .modal-slider").slick('unslick');
    //             $("#modal-gallery .modal-slider").html('');
    //         });
    //     }
    // );
  }

  function select() {
    if ($("select").length) {
      $("select:not(.cities):not(.hp)").select2({
        minimumResultsForSearch: 20,
      });
      if ($("select.cities").length) {
        $("select.cities")
          .select2({
            language: {
              noResults: function () {
                return lngNoResults;
              },
            },
            placeholder: selectCityPlaceholder,
            minimumResultsForSearch: 1,
          })
          .data("select2")
          .$dropdown.addClass("city-container-dropdown");
      }

      if ($("select.hp").length) {
        $("select.hp").select2({
          language: {
            noResults: function () {
              return lngNoResults;
            },
          },
          placeholder: searchPlaceholder,
          minimumResultsForSearch: 20,
        });

        $("select.hp")
          .data("select2")
          .on("open", function (e) {
            this.results.clear();
            this.dropdown._positionDropdown();
          });

        $("select.hp.city").each(function () {
          $(this).data("select2").$dropdown.addClass("city-container-dropdown");
        });
      }

      $(".site-content--map select").on("select2:open", function () {
        var select2Container = $(".select2-container--open")
          .not("select+.select2-container--open")
          .detach();
        select2Container.appendTo($(this).closest(".form-group"));
        $(this).closest(".form-group").addClass("active");
      });
      $(".site-content--map select").on("select2:close", function () {
        $(this).closest(".form-group").removeClass("active");
      });
    }
  }

  function homeIntroSlider() {
    // Initialize
    slideWrapper.on("init", function (slick) {
      slick = $(slick.currentTarget);
      setTimeout(function () {
        playPauseVideo(slick, "play");
      }, 1000);
      resizePlayer(iframes, 16 / 9);
    });
    slideWrapper.on("beforeChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "pause");
    });
    slideWrapper.on("afterChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "play");
    });
    slideWrapper.on("lazyLoaded", function (event, slick, image, imageSource) {
      lazyCounter++;
      if (lazyCounter === lazyImages.length) {
        lazyImages.addClass("show");
        // slideWrapper.slick("slickPlay");
      }
    });

    //start the slider
    slideWrapper.slick({
      infinite: true,
      dots: true,
      arrows: false,
      adaptiveHeight: false,
      accessibility: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    });

    // Resize event
    $(window).on("resize.slickVideoPlayer", function () {
      resizePlayer(iframes, 16 / 9);
    });
  }

  function newsSlider() {
    // Initialize
    newsSlideWrapper.on("init", function (slick) {
      slick = $(slick.currentTarget);
      setTimeout(function () {
        playPauseVideo(slick, "play");
      }, 1000);
      resizePlayer(iframes, 16 / 9);
    });
    newsSlideWrapper.on("beforeChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "pause");
    });
    newsSlideWrapper.on("afterChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "play");
    });
    newsSlideWrapper.on(
      "lazyLoaded",
      function (event, slick, image, imageSource) {
        lazyCounter++;
        if (lazyCounter === lazyImages.length) {
          lazyImages.addClass("show");
          // newsSlideWrapper.slick("slickPlay");
        }
      }
    );

    //start the slider
    newsSlideWrapper.slick({
      centerMode: true,
      centerPadding: "120px",

      focusOnSelect: true,
      dots: true,
      arrows: false,
      adaptiveHeight: false,
      accessibility: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: "0",
            // slidesToShow: 2,
          },
        },
      ],
    });

    // Resize event
    $(window).on("resize.slickVideoPlayer", function () {
      resizePlayer(iframes, 16 / 9);
    });
  }

  // POST commands to YouTube or Vimeo API
  function postMessageToPlayer(player, command) {
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  }

  // When the slide is changing
  function playPauseVideo(slick, control) {
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "vimeo") {
      switch (control) {
        case "play":
          if (
            startTime != null &&
            startTime > 0 &&
            !currentSlide.hasClass("started")
          ) {
            currentSlide.addClass("started");
            postMessageToPlayer(player, {
              method: "setCurrentTime",
              value: startTime,
            });
          }
          postMessageToPlayer(player, {
            method: "play",
            value: 1,
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            method: "pause",
            value: 1,
          });
          break;
      }
    } else if (slideType === "youtube") {
      switch (control) {
        case "play":
          postMessageToPlayer(player, {
            event: "command",
            func: "mute",
          });
          postMessageToPlayer(player, {
            event: "command",
            func: "playVideo",
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            event: "command",
            func: "pauseVideo",
          });
          break;
      }
    } else if (slideType === "video") {
      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play") {
          video.play();
        } else {
          video.pause();
        }
      }
    }
  }

  // Resize player
  function resizePlayer(iframes, ratio) {
    if (!iframes[0]) return;
    var win = slideWrapper,
      width = win.width(),
      playerWidth,
      height = win.height(),
      playerHeight,
      ratio = ratio || 16 / 9;

    iframes.each(function () {
      var current = $(this);
      if (width / ratio < height) {
        playerWidth = Math.ceil(height * ratio);
        current
          .width(playerWidth)
          .height(height)
          .css({
            left: (width - playerWidth) / 2,
            top: 0,
          });
      } else {
        playerHeight = Math.ceil(width / ratio);
        current
          .width(width)
          .height(playerHeight)
          .css({
            left: 0,
            top: (height - playerHeight) / 2,
          });
      }
    });
  }

  function stickyNav() {
    $(window).scroll(function () {
      if ($(".site-header").length > 0) {
        // $('.site-header').css('top', '0');
        if ($("body").hasClass("home")) {
          $(".site-header").toggleClass(
            "sticky",
            $(window).scrollTop() > $(".home-header").outerHeight()
          );
        } else {
          $(".site-header").toggleClass("sticky", $(window).scrollTop() > 1);
        }

        /* var heightMenu = $('.videobg').outerHeight(true);
                if($('.site-header').hasClass('sticky')) {
                    if (window.matchMedia("(min-width: 991px)").matches) {
                        $('.site-header').css('top', '0');
                        // console.log('min-width: 991');
                    }
                    if (window.matchMedia("(max-width: 991px)").matches) {
                        $('.site-header').css('top', '0');
                    //    console.log('max-width: 991');
                    }
                }  else {
                    if (window.matchMedia("(min-width: 991px)").matches) {
                        $('.site-header').css('top', heightMenu);
                    }
                    if (window.matchMedia("(max-width: 991px)").matches) {
                        $('.site-header').css('top', 'inherit');
                    }
                } */

        // if(!$('.site-header').hasClass('sticky')) {
        //     if (window.matchMedia("(min-width: 991px)").matches) {
        //         $('.site-header').css('top', heightMenu);
        //     }
        //     if (window.matchMedia("(max-width: 991px)").matches) {
        //         $('.site-header').css('top', 'inherit');
        //     }
        // }
      }
    });
  }

  function smoothScroll() {
    $(window).on("load", function () {
      var urlHash = window.location.href.split("#")[1];
      if (urlHash && $("#" + urlHash).length)
        $("html,body").animate(
          {
            scrollTop: $("#" + urlHash).offset().top - 120,
          },
          800
        );
    });

    $(document).on("click", 'a[href*="#"]:not([href="#"])', function () {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 120,
            },
            800
          );
          return false;
        }
      }
    });
  }

  function resize() {
    winW = window.innerWidth;
    winH = window.innerHeight;
  }

  function fixAside() {
    if (winH > $(".aside-datas .aside-inner").outerHeight() && winW > 991) {
      var headerH = $(".site-header").outerHeight();
      var footerH =
        $(".site-footer").outerHeight() +
        $(".content-footer").outerHeight() +
        100;
      $(".aside-datas .aside-inner").sticky({
        topSpacing: headerH,
        bottomSpacing: footerH,
      });
      $(".aside-datas .aside-inner").on("sticky-start", function () {
        $(this).closest(".sticky-wrapper").addClass("sticky");
      });
      $(".aside-datas .aside-inner").on("sticky-end", function () {
        $(this).closest(".sticky-wrapper").removeClass("sticky");
      });
    } else {
      $(".aside-datas .aside-inner").unstick();
    }
  }

  function slick() {
    if ($(".highlight-slider").length) {
      $(".highlight-slider").each(function (index) {
        $this = $(this);

        if ($this.find(".slider-item").length > 1) {
          $this.slick({
            dots: true,
            arrows: false,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
          });
        }
      });
    }

    if ($(".block-slider:not(.single-slider)").length) {
      $(".block-slider:not(.single-slider) .slider").each(function (index) {
        $this = $(this);

        if ($this.find(".slider-item").length > 1) {
          $this.slick({
            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: false,
            accessibility: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }
      });
    }

    if ($(".card-list-slider").length) {
      $(".card-list-slider").each(function (index, element) {
        $this = $(this);

        if ($this.find(".card:not(.card--file):not(.card-link-ctn)").length) {
          $this.slick({
            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
            accessibility: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 799,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 579,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        }

        if ($this.find(".card.card--file").length) {
          $this.slick({
            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
            accessibility: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 9999,
                settings: "unslick",
              },
              {
                breakpoint: 579,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        }

        if ($this.find(".card-link-ctn").length) {
          $this.slick({
            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
            accessibility: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 579,
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: "65px",
                },
              },
              {
                breakpoint: 379,
                settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: "45px",
                },
              },
            ],
          });
        }

        if ($this.find(".card-overlay-ico").length) {
          $this.slick({
            infinite: true,
            dots: true,
            arrows: false,
            adaptiveHeight: true,
            accessibility: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 9999,
                settings: "unslick",
              },
              {
                breakpoint: 579,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
        }

        if ($this.find(".pushs-item").length) {
          if ($(".site-content").hasClass("site-content--default")) {
            $this.slick({
              infinite: true,
              dots: true,
              arrows: false,
              adaptiveHeight: true,
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 9999,
                  settings: "unslick",
                },
                {
                  breakpoint: 639,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            });
          } else {
            $this.slick({
              infinite: true,
              dots: true,
              arrows: false,
              adaptiveHeight: true,
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 9999,
                  settings: "unslick",
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 579,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            });
          }
        }
      });
    }
  }

  function change(state) {
    if (state !== null) {
      // initial page
      loader(true);
      $("#page_filters_container").html(state);
      loader(false);
      if (!$("#page_filters_container").find(".select2-container").length) {
        select();
      }
    }
  }

  function homepageSearch() {
    var currentFormHome = "";
    $("#home_form_activities").slideUp(0).css("opacity", 1);
    $(document).on("change", ".home-form-search-type", function (e) {
      var formToShow = $(this).val();

      $(".form-depends-search").slideUp(300, function () {
        setTimeout(function () {
          $("#" + formToShow).slideDown(300);
        }, 150);
      });
    });

    $(document).on("submit", ".homepage-search-form", function (e) {
      e.preventDefault();

      var typeUri = $(this).find(".search-type").val();
      var selectCity = $(this).find(".search-city").val();

      if (typeUri && selectCity) {
        typeUri += "?place=" + selectCity;
        window.location = typeUri;
      }

      return;
    });

    $(".activity-up").on("click", function () {
      $("#type_activity").prop("checked", true).trigger("change");
    });
  }

  function formSyndications() {
    if ($("#page_filters_container").length) {
      history.replaceState($("#page_filters_container").html(), null, null);
    }

    (function (original) {
      // overwrite history.pushState so that it also calls
      // the change function when called
      history.pushState = function (state) {
        change(state);
        return original.apply(this, arguments);
      };
    })(history.pushState);

    $(document).on("change", "#type_val_form", function (e) {
      e.preventDefault();
      loader(true);
      var typeUri = $(this).val();
      var cNewTitle = $("#type_val_form option:selected").text();
      $("#page_filters_container").load(typeUri, function (ajaxContent) {
        select();
        $(".content-header h1").html(cNewTitle);
        loader(false);
        history.pushState(ajaxContent, null, typeUri);
      });
    });

    $(document).on("change", "#place_val_form", function (e) {
      e.preventDefault();
      loader(true);
      var typeUri = encodeURI($(this).val());
      $("#page_filters_container").load(typeUri, function (ajaxContent) {
        select();
        loader(false);
        history.pushState(ajaxContent, null, typeUri);
      });
    });

    $(document).on("change", ".filters-select", function (e) {
      e.preventDefault();
      loader(true);
      var typeUri = $(this).val();
      $("#page_filters_container").load(typeUri, function (ajaxContent) {
        select();
        loader(false);
        history.pushState(ajaxContent, null, typeUri);
      });
    });

    $(document).on(
      "change",
      "#types_select_form .filters-checkboxes",
      function () {
        var isChecked = $(this).is(":checked");
        var cUrl = $(this).attr("data-url");
        loader(true);
        $("#page_filters_container").load(cUrl, function (ajaxContent) {
          select();
          loader(false);
          history.pushState(ajaxContent, null, cUrl);
        });
      }
    );

    $(window).on("popstate", function (e) {
      change(e.originalEvent.state);
    });
  }

  function loader(show) {
    if (show) {
      $("body").addClass("loading");
    } else {
      $("body").removeClass("loading");
    }
  }

  var timeoutId;

  // function videobgEnlarge() {

  //     var videoBgAspect = $(".videobg-aspect");
  //     var videoBgWidth = $(".videobg-width");
  //     var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
  //     var windowAspect = ($(window).height() / $(window).width());

  //     if (windowAspect > videoAspect) {
  //         videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
  //     } else {
  //         videoBgWidth.width(100 + "%")
  //     }

  // }

  // $(window).resize(function() {
  //   clearTimeout(timeoutId);
  //   timeoutId = setTimeout(videobgEnlarge, 100);
  // });

  // $(function() {
  //   videobgEnlarge();
  // });

  function videoHeight() {
    var height = $(".videobg-make-height").outerHeight(true);
    console.log(height);
    var heightMenu = $(".videobg").outerHeight(true);

    $(".videobg").css("height", height);
    if (window.matchMedia("(min-width: 991px)").matches) {
      $(".site-header").css("top", heightMenu);
    }
  }

  $(window).resize(function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(videoHeight, 100);
    // videoHeight();
  });
  $(function () {
    videoHeight();
  });
});
